import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import background from './assets/images/background.png';
import mockup from './assets/images/Phone 04.png';
import logo from './assets/images/logo.svg';
import google from './assets/images/googleCard.png';
import apple from './assets/images/appleCard.png';
import CircleRightArrowImage from './assets/images/right-arrow.svg';
import RightArrowImage from './assets/images/right.svg';
import PrivacyPolicy from './assets/Modal/PrivacyPolicy';
import TermsOfUse from './assets/Modal/TermsOfUse';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import EmailConfirmation from './assets/Modal/EmailConfirmation';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email not valid').required('Email required!'),
});
const App: React.FC<{}> = () => {
  const [showModal, setModal] = useState<string>();
  const [subscribe, setSubscribe] = useState<boolean>(false);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setSubscribe(true);
      fetch('https://gomint.env.wtmsrv.com/api/v1.0/newsletter/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: values.email }),
      })
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            setSubscribe(false);
          }, 1000);
          if (res) resetForm();
        })
        .catch((err) => console.log(err));
    },
  });
  React.useEffect(() => {
    const params = window.location.pathname;
    const hash = window.location.hash;
    if (params.includes('Terms&Conditions')) {
      setModal('ShowTermsModal');
    } else if (params.includes('PrivacyPolicy')) {
      setModal('ShowPrivacyModal');
    } else if (hash.includes('#EmailConfirmed')) {
      setModal('#EmailConfirmed');
    } else {
      return;
    }
  }, []);

  React.useEffect(() => {
    if (showModal === '#EmailConfirmed') {
      const timeoutId = setTimeout(() => {
        setModal('');
      }, 3000); // Hide toast after 3 seconds
      return () => clearTimeout(timeoutId);
    }
  }, [showModal]);

  const GotoTerms = () => {
    window.history.pushState(null, '', 'Terms&Conditions');
    setModal('ShowTermsModal');
  };
  const GotoPrivacy = () => {
    window.history.pushState(null, '', 'PrivacyPolicy');
    setModal('ShowPrivacyModal');
  };

  return (
    <>
      <GlobalStyle />
      <MainContainer>
        <Container>
          <LeftSide>
            <Logo />
            <TextHeading></TextHeading>
            <ButtonsDiv>
              <DownloadBtn href="https://apps.apple.com/ca/app/gomint/id6472703427" target="_blank">
                <img src={apple} alt="apple-download-btn" />
              </DownloadBtn>
              <DownloadBtn
                href="https://play.google.com/store/apps/details?id=dev.wtm.gomint"
                target="_blank"
              >
                <img src={google} alt="google-download-btn" />
              </DownloadBtn>
            </ButtonsDiv>
            <Text>Get notified when we are live</Text>
            <InputWrapper>
              <EmailInput
                placeholder="Your Email Address"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                name="email"
              />
              <CircleRightArrow onClick={() => handleSubmit()}>
                <img src={CircleRightArrowImage} alt="Right-Arrow" />
              </CircleRightArrow>
            </InputWrapper>
            {subscribe && <ValidationMessage>Thank You !</ValidationMessage>}
            {touched.email && errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            <ContactWrapper>
              <ContactText>Contact us</ContactText>
              <RightArrow>
                <img src={RightArrowImage} alt="Right-Arrow" />
              </RightArrow>
            </ContactWrapper>
            <ModalWrapper>
              {' '}
              <ModalText onClick={GotoTerms}>Terms & Conditions</ModalText>
              <Text>-</Text>
              <ModalText onClick={GotoPrivacy}>Privacy Policy</ModalText>
            </ModalWrapper>
            <CopyRightText>
              ©{moment().format('YYYY')} Home Matrix for Training and Platform Management Co. All
              Rights Reserved
            </CopyRightText>
          </LeftSide>
          <RightSide>
            <PhoneImage />
          </RightSide>
        </Container>
        {showModal === 'ShowPrivacyModal' && <PrivacyPolicy hide={setModal} />}
        {showModal === 'ShowTermsModal' && <TermsOfUse hide={setModal} />}
        {showModal === '#EmailConfirmed' && <EmailConfirmation hide={setModal} />}
      </MainContainer>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  *{ 
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
  }
`;

const MainContainer = styled.div`
  background-image: url('${background}');
  min-width: 100vw;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 13vh;

  @media (max-width: 757px) {
    padding-top: 6vh;
    justify-content: center;
  }

  @media (max-width: 400px) {
    width: 320px;
    margin: auto;
  }

  @media (min-height: 1000px) {
    padding-top: 15%;
  }

  @media (min-width: 1250px) and (min-height: 899px) {
    padding-top: 8%;
  }
`;

const LeftSide = styled.div`
  height: 100%;
`;

const RightSide = styled.div`
  height: 100%;
`;

const PhoneImage = styled.div`
  background-image: url('${mockup}');
  background-repeat: no-repeat;
  width: 322px;
  background-size: 100%;
  height: 436px;

  @media (max-width: 1400px) {
    margin-top: 120px;
  }

  @media (max-width: 1200px) {
    margin-top: 40px;
  }

  @media (max-width: 757px) {
    display: none;
  }

  @media screen and (min-height: 1000px) {
    margin-top: 170px;
  }

  @media (min-width: 1250px) and (min-height: 899px) {
    margin-top: 100px;
  }
`;

const Logo = styled.div`
  background-image: url('${logo}');
  width: 355px;
  height: 75px;
  background-repeat: no-repeat;

  @media (max-width: 550px) {
    width: 256px;
    height: 73px;
    margin: auto;
  }
`;

const Text = styled.h3`
  font-size: 15px;
  font-weight: 300;
  color: white;
  margin-left: 10px;
`;

const TextHeading = styled(Text)`
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 5px;

  @media (max-width: 757px) {
    margin-top: 15vh;
  }

  @media (min-width: 1250px) and (min-height: 899px) {
    margin-top: 10vh;
  }

  @media (min-height: 1000px) {
    margin-top: 100px;
  }

  @media (min-width: 1700px) {
    margin-top: 12vh;
  }

  @media (min-width: 1800px) {
    margin-top: 13vh;
  }

  @media (min-width: 1900px) {
    margin-top: 14vh;
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;

  @media (max-width: 400px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const DownloadBtn = styled.a`
  width: 100%;
  height: 100%;
  cursor: pointer;

  @media (max-width: 400px) {
    text-align: center;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  @media (max-width: 360px) {
    width: 280px;
    margin: auto;
  }
`;

const EmailInput = styled.input`
  width: 100%;
  height: 42px;
  border: 2px solid gray;
  border-radius: 25px;
  margin: 15px 0px;
  padding: 5px 15px;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a7a7a7;
  }
`;

const CircleRightArrow = styled.div`
  position: absolute;
  width: 27px;
  height: 27px;
  top: 23px;
  right: 18px;
  cursor: pointer;
  overflow: hidden;

  img {
    transition: opacity 0.3s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 30px;
  transition: width 0.3s ease-out;

  &:hover {
    width: 170px;
  }
`;

const ContactText = styled(Text)`
  font-size: 20px;
`;

const RightArrow = styled.div`
  padding-left: 10px;
`;

const ModalWrapper = styled.div`
  display: flex;
  margin: 15px 0px 30px;
`;

const ModalText = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CopyRightText = styled(Text)`
  padding-bottom: 10px;
`;

const ErrorMessage = styled.p`
  position: relative;
  color: black;
  margin-top: -10px;
  padding-left: 10px;
`;

const ValidationMessage = styled(Text)`
  font-size: 18px;
  margin: 0 10px 0;
  color: #000000;
`;

export default App;
