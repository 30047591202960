import React from 'react';
import styled, { keyframes } from 'styled-components';
import CloseIconImage from '../images/Icon ionic-ios-close-circle-outline.svg';

interface PrivacyPolicyProps {
  // eslint-disable-next-line no-unused-vars
  hide: (value: string) => void;
}

const PrivacyPolicy = ({ hide }: PrivacyPolicyProps) => {
  const locale = new URL(window.location.href)?.searchParams?.get('locale');
  const isRTL = locale === 'ar';
  return (
    <Container>
      <InnerContainer>
        <Flex reverse={isRTL}>
          <Heading>{isRTL ? 'سياسة الخصوصية' : 'Privacy Policy'}</Heading>
          <CloseIcon onClick={() => hide('')}>
            <img src={CloseIconImage} alt="close-icon" />
          </CloseIcon>
        </Flex>
        <MainWrapper>
          <SubHeading
            id="user-privacy-policy"
            style={{ fontSize: '24px', paddingTop: '20px', paddingBottom: '20px' }}
          >
            {isRTL ? 'سياسة خصوصية المستخدم' : 'User Privacy Policy'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>{isRTL}</Paragraph>
          {/* add the new text here */}
          <SubHeading>
            {isRTL ? 'معلومات الاتصال الشخصية' : 'Personal Contact Information'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تقوم Gomint بجمع أنواع مختلفة من المعلومات من مستخدمي تطبيقها الجوال. يشمل ذلك معلومات التعرف الشخصي (PII) مثل الاسم والعنوان وتفاصيل الاتصال وموقع GPS. قد تقوم Gomint أيضًا بجمع معلومات حول طلبات المستخدمين السابقة وتفضيلاتهم وآرائهم وأنشطتهم على وسائل التواصل الاجتماعي. بالإضافة إلى ذلك، قد تقوم Gomint بجمع بيانات صحية، بما في ذلك وصفات الأدوية ومعلومات التأمين، في ظل شروط صارمة محددة في قسم معلومات البيانات الصحية وفقًا للتنظيمات السارية.`
              : `Gomint collects various types of information from users of its mobile application. This includes personally identifiable information (PII) such as name, address, contact details, and GPS location. Gomint may also collect information about users' past orders, preferences, reviews, and social networking activities. Additionally, Gomint may collect health data, including medical prescriptions and insurance information, under strict conditions outlined in the Health Data Information section and in accordance with applicable regulations.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تستخدم Gomint أيضًا برامج التحليل لتتبع وتحليل حركة المرور على تطبيقها الجوال لأغراض الإعلان والترويج. قد يتم مشاركة هذه البيانات مع أطراف ثالثة ولكنها لا تتضمن PII. تجمع Gomint أيضًا بيانات غير معرفة شخصيًا (Non-PII) مثل عنوان MAC ونوع الجهاز ودقة الشاشة ونظام التشغيل والمتصفح وبيانات الفئة الديموغرافية. يمكن استخدام هذه البيانات لحساب إحصائيات عامة حول المستخدمين، مثل نسبة المستخدمين من منطقة ترميز معينة.`
              : `Gomint also uses analytics software to track and analyze traffic on the mobile application for advertising and promotion purposes. This data may be shared with third parties but does not include PII. Gomint also collects non-personally identifiable information (Non-PII) such as MAC address, device type, screen resolution, operating system, browser, and demographic data. This data may be used to calculate general user statistics, such as the percentage of users from a particular area code.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `يمكن استخدام الكوكيز ووسوم البكسل وأدوات الويب الأخرى المماثلة لتوفير معلومات مخصصة أكثر للمستخدمين وتسهيل استخدام التطبيق. يمكن للمستخدمين رفض استخدام ملفات تعريف الارتباط عن طريق ضبط إعدادات المتصفح الخاصة بهم. يتم تسجيل عناوين IP، التي يتم تخصيصها للكمبيوترات من قبل مزودي خدمة الإنترنت (ISPs)، تلقائيًا في سجلات خادم Gomint مع الطوابع الزمنية والصفحات المُزارة. وهذا هو ممارسة قياسية على الإنترنت.`
              : `Cookies, pixel tags, web beacons, and other similar technologies may be used to provide users with more tailored information and facilitate their use of the application. Users can decline the use of cookies by adjusting their browser settings. IP addresses, assigned to computers by Internet Service Providers (ISPs), are automatically logged in Gomint's server log files along with timestamps and visited pages. This is a standard practice on the Internet.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'استخدام معلوماتك' : 'Use of Your Information'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تستخدم Gomint معلومات التعرف الشخصي (PII) لأغراض متنوعة، بما في ذلك:`
              : `Gomint utilizes personally identifiable information (PII) for various purposes, including:`}
            <br />
            {isRTL
              ? `إنشاء حسابات المستخدمين: يستخدم Gomint PII لإنشاء وإدارة حسابات المستخدمين داخل التطبيق.`
              : `1- Establishing user accounts: Gomint employs PII to create and manage user accounts within the application.`}
            <br />
            {isRTL
              ? `تعزيز التواصل مع المستخدم: يستخدم Gomint PII لتسهيل التواصل الفعال مع المستخدمين بشأن خدمات وعروض Gomint.`
              : `2- Enhancing user communication: Gomint utilizes PII to facilitate effective communication with users regarding Gomint services and offerings.`}
            <br />
            {isRTL
              ? `خدمات وعروض مخصصة: يستفيد Gomint من PII لتوفير خدمات وعروض مخصصة وعروض خاصة تلبي تفضيلات المستخدمين.`
              : `3- Personalized services and promotions: Gomint leverages PII to provide users with tailored services, promotions, and special offers catering to their preferences.`}
            <br />
            {isRTL
              ? `عمليات الشراء: يعالج Gomint PII لتسهيل وإكمال عمليات الشراء التي تتم من خلال التطبيق.`
              : `4- Purchase transactions: Gomint processes PII to facilitate and complete purchase transactions made through the application.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تستخدم Gomint الكوكيز لتعزيز تجربة المستخدم عن طريق التعرف على المستخدمين العائدين وتوفير لهم معلومات مخصصة، مثل بيانات تسجيل الدخول وتفضيلات اللغة واختيار البلد.`
              : `Gomint employs cookies to enhance user experience by recognizing returning users and providing them with personalized information, such as login credentials, language preferences, and country selection.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تلتزم Gomint بسياسة صارمة عدم بيع معلومات PII لأطراف ثالثة. ومع ذلك، قد تشارك Gomint معلومات PII مع بعض الموردين ومقدمي الخدمات الذين يساعدون في تقديم خدمات Gomint من خلال واجهات تطبيق التكامل (APIs) ووظائف أخرى.`
              : `Gomint adheres to a strict policy of not selling user PII to third parties. However, it may share PII with select third-party vendors and service providers who assist in delivering Gomint services through application programming interfaces (APIs) and other functionalities.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `لتسهيل معالجة الطلبات، تشارك Gomint تفاصيل طلبات المستخدمين، بما في ذلك التعليمات الخاصة والأسماء والعناوين وأرقام الهواتف، مع المطاعم ذات الصلة حيث تتم وضع الطلبات.`
              : `To facilitate order processing, Gomint shares users' order details, including special instructions, names, addresses, and phone numbers, with the respective restaurants where the orders are placed.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `في حالات استثنائية، قد تكشف Gomint PII لأطراف ثالثة، مثل المستشارين القانونيين، ووكالات التحصيل، والمحكمات، أو السلطات الأمنية، ردًا على طلبات صحيحة تتعلق بانتهاكات محتملة لشروط استخدام Gomint أو التزامات أخرى، أو لمنع الضرر للأفراد أو الممتلكات.`
              : `In exceptional circumstances, Gomint may disclose PII to third parties, such as legal counsel, collection agencies, tribunals, or law enforcement authorities, in response to valid requests related to potential violations of Gomint's terms of use or other contractual obligations, or to prevent harm to individuals or property.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `بالنسبة للمعلومات غير التعرف الشخصي (Non-PII)، لا تقتصر Gomint بنفس القيود الخصوصية المفروضة على PII. يمكن استخدام Non-PII لأغراض متنوعة، بما في ذلك المشاركة مع الشركات التابعة وأطراف ثالثة أخرى. في بعض الحالات، قد يقوم Gomint بدمج Non-PII مع PII، مثل ربط اسم المستخدم بموقعه الجغرافي. إذا تم دمج Non-PII مع PII، سيتم التعامل مع البيانات الناتجة كمعلومات تعرف شخصيًا وتخضع لنفس حماية الخصوصية.`
              : `Regarding non-personally identifiable information (Non-PII), Gomint is not restricted by the same privacy limitations as with PII. Non-PII may be utilized for various purposes, including sharing with affiliates and other third parties. In some instances, Gomint may combine Non-PII with PII, such as associating a user's name with their geographical location. If Non-PII is combined with PII, the resulting data will be treated as PII and subject to the same privacy protections.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'رقم هاتفك المحمول' : 'Your Mobile Number'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `للتسجيل في حسابنا على تطبيق الجوال، يجب عليك توفير رقم هاتفك المحمول أو معلومات الاتصال الصحيحة الأخرى. نستخدم هذه المعلومات لتأكيد طلباتك، والتواصل معك بشأن قضايا ذات صلة بالطلب، وتعزيز تجربتك العامة كمستخدم.`
              : `To register for an account on our mobile application, you must provide us with your mobile number or other valid contact information. We utilize this contact information to confirm your orders, communicate with you regarding order-related issues, and enhance your overall user experience.`}
            <br />
            {isRTL
              ? `إذا قدمت لنا رقم هاتفك المحمول، يمكنك إدارته وتحديثه وتعديله وتصحيح أي عدم دقة عن طريق الوصول إلى صفحة ملف تعريف حسابك.`
              : `If you supply us with your mobile number, you can manage it, update it, modify it, and rectify any inaccuracies by accessing your account's profile page.`}
            <br />
            {isRTL
              ? `على الرغم من أنه يمكنك اختيار عدم تقديم رقم هاتفك المحمول، إلا أنك لن تكون قادرًا على التسجيل على تطبيق الجوال أو تقديم طلبات.`
              : `While you can choose not to provide your mobile number, you will not be able to register on the mobile application or place orders.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `عند تقديم طلب، نشارك جزءًا من معلومات الاتصال الخاصة بك (الاسم واللقب وعنوان تسليم الطلب ورقم الهاتف) مع المطعم الشريك. هذه المعلومات ضرورية لتسهيل معالجة الطلب وتحسين تجربتك كمستخدم.`
              : `When placing an order, we share a portion of your contact information (name, surname, order delivery address, and phone number) with the partner restaurant. This information is essential for facilitating order processing and improving your user experience.`}
            <br />
            {isRTL
              ? `قد نستخدم أيضًا هذه المعلومات الاتصال لأغراض داخلية وللامتثال للمتطلبات القانونية. قد يتم الكشف عن معلومات الاتصال الخاصة بك لموظفينا والأطراف الثالثة المشاركة في تسليم الطلب، أو تحليل الطلب، أو خدمات دعم المستخدم.`
              : `We may also use this contact information for internal purposes and to comply with legal requirements. Your contact information may be disclosed to our staff and third parties involved in order delivery, order analysis, or user support services.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `نلتزم بمبادئ الخصوصية الصارمة ونمتنع عن بيع أو الكشف عن معلومات الاتصال الشخصية الخاصة بك لأطراف ثالثة ما لم يتم ذلك بصراحة في سياسة الخصوصية هذه.`
              : `We adhere to strict privacy principles and refrain from selling or divulging your personal contact information to third parties unless explicitly stated in this Privacy Policy.`}
            <br />
            {isRTL
              ? `قد تحدث استثناءات من هذه السياسة إذا كنا ملزمين قانونيًا أو مضطرين للكشف عن مثل هذه المعلومات، كما هو الحال في الامتثال لقرارات المحكمة أو لمنع الاحتيال أو أنشطة أخرى غير قانونية.`
              : `Exceptions to this policy may arise if we are legally obligated or compelled to disclose such information, such as in compliance with court orders or for the prevention of fraud or other criminal activities.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'معلومات طلب الطعام' : 'Order Placement Information'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `يتطلب عملية تقديم الطلب منك تقديم معلومات شخصية، بما في ذلك اسمك وعنوانك ورقم هاتفك وعنوان بريدك الإلكتروني وتفاصيل أخرى مماثلة ضرورية للتعرف وإكمال طلب الطعام.`
              : `The order process requires you to provide personal information, including your name, address, phone number, email address, and other similar details necessary for identification and order fulfillment.`}
            <br />
            {isRTL
              ? `سيتم مشاركة هذه المعلومات الشخصية التي تم جمعها من خلال تطبيقنا على الهاتف المحمول مع المطعم الذي يقوم بمعالجة طلبك.`
              : `This personal information collected through our mobile application will be shared with the restaurant handling your order.`}
            <br />
            {isRTL
              ? `لقد أقمنا اتفاقيات مع جميع المطاعم الشريكة، تحظر بشكل صارم الكشف غير المصرح به أو معالجة مزيدة للمعلومات الشخصية التي تم مشاركتها من قبلنا.`
              : `We have established agreements with all partner restaurants, strictly prohibiting the unauthorized disclosure or further processing of personal information shared by us.`}
            <br />
            {isRTL
              ? `ستستخدم معلوماتك الشخصية حصريًا لإعلامك بحالة طلبك. بدون موافقتك الصريحة، لن نشارك معلوماتك الشخصية مع أي شركاء تجاريين أو فروع.`
              : `Your personal information will be used exclusively to inform you about the status of your order. Without your explicit consent, we will not share your personal information with any business partners or affiliates.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `نعامل معلوماتك الشخصية كسرية ولن نكشف عنها لأطراف خارجية ما لم يفرضه التشريعات السارية.`
              : `We treat your personal information as confidential and will not disclose it to external parties unless compelled by applicable legislation.`}
            <br />
            {isRTL
              ? `نرسل رسائل تسويقية فقط إلى الأفراد الذين طلبوا بشكل صريح تلقي مثل هذه الاتصالات.`
              : `We only send marketing emails to individuals who have specifically requested to receive such communications.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'معلومات الصحة' : 'Health Data Information'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `عند تقديم طلب للحصول على وصفة طبية من خلال تطبيقنا على الهاتف المحمول، ستحتاج إلى توفير معلومات الصحة الخاصة بك ومعلومات التعرف.`
              : `When placing an order for prescription medication through our mobile application, you'll be required to provide your health data and identification information.`}
            <br />
            {isRTL
              ? `خلال مرحلة معالجة الطلب، نقوم بنقل بيانات صحتك وبيانات الهوية بشكل آمن إلى الصيدلية المحددة باستخدام بروتوكولات ومعايير التشفير المعترف بها عالميًا.`
              : `During the order processing stage, we securely transmit your health data and ID to the selected pharmacy utilizing globally recognized encryption protocols and standards.`}
            <br />
            {isRTL
              ? `لا تحتفظ Gomint بأي وصول إلى بيانات صحتك. لقد أقمنا اتفاقيات مع جميع الصيدليات الشريكة، تحظر بشكل صارم الكشف غير المصرح به أو معالجة مزيدة لبيانات الصحة التي تم مشاركتها من قبلنا.`
              : `Gomint maintains no access to your health data. We have established agreements with all partner pharmacies, strictly prohibiting the unauthorized disclosure or further processing of health data shared by us.`}
            <br />
            {isRTL
              ? `ستستخدم بيانات صحتك حصريًا من قبل الصيدلية المحددة لتحقيق طلب الوصفة الطبية الخاص بك.`
              : `Your health data will be exclusively used by the selected pharmacy to fulfill your prescription medication order.`}
          </Paragraph>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تمتنع Gomint عن تخزين أي من بيانات صحتك. قد نحتفظ بنسخة من معلومات التعرف الخاصة بك لأغراض التحقق من الهوية وتسجيل السجلات.`
              : `Gomint refrains from storing any of your health data. We may retain a copy of your identification information for identity verification and record-keeping purposes.`}
          </Paragraph>
          <SubHeading>
            {isRTL ? 'استخدام شركات الإعلانات' : 'Use of Advertising Companies'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `قد نستخدم شركات الإعلانات لخدمة الإعلانات نيابةً عننا.`
              : `We may use advertising companies to serve ads for us.`}
            <br />
            {isRTL
              ? `قد تستخدم هذه الشركات ملفات تعريف الارتباط وعلامات العمل لتقييم فعالية الإعلان وتقديم محتوى إعلاني مخصص أو توصيات لك على تطبيقنا على الهاتف المحمول أو مواقعهم الإلكترونية.`
              : `These companies may utilize cookies and action tags to assess advertising effectiveness and provide tailored advertising content or recommendations to you on our mobile application or their own websites.`}
            <br />
            {isRTL
              ? `أي معلومات يتم جمعها من قبل هذه الأطراف الثالثة من خلال ملفات تعريف الارتباط وعلامات العمل هي تمامًا مجهولة ولا تتضمن أي تفاصيل شخصية للاتصال.`
              : `Any information collected by these third parties through cookies and action tags is entirely anonymous and does not include any personal contact details.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'التسويق' : 'Marketing'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `نحترم خصوصيتك ولا نشارك في بيع أو تأجير معلوماتك الشخصية لأطراف ثالثة لأغراض التسويق من دون الحصول على موافقتك الصريحة.`
              : `We respect your privacy and do not engage in the sale or rental of your personal information to third parties for their marketing purposes without obtaining your explicit consent.`}
            <br />
            {isRTL
              ? `ومع ذلك، قد نقوم بدمج معلوماتك مع البيانات المجموعة من مصادر أخرى لتعزيز وتخصيص خدماتنا ومحتوانا وإعلاناتنا.`
              : `We may, however, combine your information with data gathered from other sources to enhance and personalize our services, content, and advertising.`}
            <br />
            {isRTL
              ? `إذا كنت ترغب في عدم تلقي الاتصالات التسويقية منا عبر البريد الإلكتروني أو المشاركة في برامج تخصيص الإعلانات لدينا، ما عليك سوى إبلاغنا بتفضيلك عبر البريد الإلكتروني.`
              : `If you wish to opt out of receiving marketing communications from us via email or participating in our ad-customization programs, simply inform us of your preference via email.`}
          </Paragraph>
          <SubHeading>
            {isRTL ? 'هل نكشف عن معلوماتك الشخصية؟' : 'Do we disclose your personal information?'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `قد نشارك معلوماتك مع موظفينا ومع أطراف ثالثة معنية في تنفيذ طلباتك، وتحليل أنماط استخدامك، وتوفير الدعم لتطبيق الهاتف المحمول.`
              : `We may share your information with our staff and with third parties involved in fulfilling your orders, analyzing your usage patterns, and providing support for the mobile application.`}
            <br />
            {isRTL
              ? `قد نكشف أيضًا عن معلوماتك إذا اعتبر ذلك ضروريًا لحماية حقوقنا القانونية، في حالات تشمل سلوكًا فعليًا أو محتملاً ضارًا، أو إذا كان لدينا اعتقاد معقول بأن مثل هذا الكشف ضروري لـ:`
              : `We may also disclose your information if deemed necessary to safeguard our legal rights, in cases involving actual or potential harmful conduct, or if we have a reasonable belief that such disclosure is essential to:`}
            <br />
            {isRTL
              ? `1- الامتثال للمتطلبات القانونية أو الامتثال لتوجيهات الحكومة أو القرارات القضائية أو الإجراءات القانونية المقدمة إلينا، أو`
              : `1- adhere to legal requirements or comply with government mandates, court orders, or legal proceedings served upon us, or`}
            <br />
            {isRTL
              ? `2- حماية ودفع ملكيتنا أو حقوقنا الأخرى، أو مستخدمي تطبيق الهاتف المحمول لدينا، أو الجمهور العام. ويشمل ذلك تبادل المعلومات مع شركات ومنظمات أخرى لأغراض الوقاية من الاحتيال وتخفيف مخاطر الائتمان.`
              : `2- protect and defend our property or other rights, our mobile application users, or the general public. This includes exchanging information with other companies and organizations for fraud prevention and credit risk mitigation purposes.`}
          </Paragraph>
          <SubHeading>
            {isRTL
              ? 'كيف يتم الكشف عن معلومات التعريف الشخصية؟'
              : 'How is personally identifiable information disclosed?'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `قد نكشف عن معلومات التعريف الشخصية (PII) للأطراف التالية:`
              : `We may disclose personally identifiable information (PII) to the following parties:`}
            <br />
            {isRTL
              ? `مقدمي خدمات الطرف الثالث: قد نشارك PII مع مقدمي خدمات الطرف الثالث الذين يساعدوننا في تشغيل أعمالنا، مثل مقدمي خدمات استضافة المواقع، وشركات تحليل البيانات، ومعالجي الدفع، وشركات تنفيذ الطلبات، ومقدمي البنية التحتية، ومقدمي خدمات تكنولوجيا المعلومات، ومقدمي خدمات العملاء، وخدمات توصيل البريد الإلكتروني، ومعالجي بطاقات الائتمان، وغيرهم. يُطلب من هؤلاء مقدمي الخدمة حماية PII الخاص بك واستخدامه فقط للأغراض المصرح بها من قبلنا.`
              : `Third-party service providers: We may share PII with third-party service providers who assist us in operating our business, such as website hosting providers, data analytics firms, payment processors, order fulfillment companies, infrastructure providers, IT service providers, customer service providers, email delivery services, credit card processors, and others. These service providers are required to safeguard your PII and use it only for the purposes authorized by us.`}
            <br />
            {isRTL
              ? `شركاء المطاعم: لتنفيذ طلباتك، قد نشارك PII الخاص بك مع شركاء المطاعم. هذه المعلومات ضرورية لمعالجة وتوصيل طلباتك.`
              : `Restaurant partners: To fulfill your orders, we may share your PII with partner restaurants. This information is essential for processing and delivering your orders.`}
            <br />
            {isRTL
              ? `رعاة الترويج: قد نشارك PII الخاص بك مع رعاة الترويج من الطرف الثالث، سواء كانوا يستضيفونها نحن أو الآخرين، وفقًا لقوانين تلك الترويجات. يجب عليك مراجعة قوانين كل ترويج تشارك فيه، حيث قد تحتوي على معلومات إضافية حول كيفية استخدام الراعي أو الأطراف الثالثة الأخرى لـ PII الخاص بك. إذا تعارضت شروط وأحكام قوانين الترويج مع سياسة الخصوصية لدينا بشأن التعامل مع PII الخاص بك، ستكون قوانين الترويج هي الأولوية.`
              : `Promotion sponsors: We may share your PII with third-party sponsors of promotions, whether hosted by us or others, in accordance with the rules of those promotions. You should carefully review the rules of each promotion you participate in, as they may contain additional information about how the sponsor or other third parties may use your PII. If the terms and conditions of a promotion's rules conflict with our Privacy Policy regarding the handling of your PII, the promotion's rules will take precedence.`}
            <br />
            {isRTL
              ? `نقل الأعمال: في حالة إعادة تنظيم الأعمال أو الدمج أو البيع أو التحالف أو التخصيص أو النقل أو أي تصرف آخر يشمل كل أو جزءًا من أعمالنا أو أصولنا أو أسهمنا، قد نشارك PII الخاص بك مع الكيان الذي يقوم بالاستحواذ أو الأطراف ذات الصلة الأخرى.`
              : `Business transfer: In the event of a business reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock, we may share your PII with the acquiring entity or other relevant parties.`}
            <br />
            {isRTL
              ? `الامتثال القانوني والتنظيمي: قد نكشف عن PII الخاص بك حسب القانون، بما في ذلك القوانين خارج بلد إقامتك، للامتثال للعمليات القانونية، والاستجابة لطلبات السلطات العامة والحكومية، بما في ذلك تلك خارج بلد إقامتك، لفرض شروطنا وأحكامنا، لحماية عملياتنا أو تلك التابعة لشركاتنا الفرعية، لحماية حقوقنا، خصوصيتنا، سلامتنا، أو ممتلكاتنا، و/أو تلك التابعة لشركاتنا الفرعية، أو لك، أو للآخرين، ولمتابعة الحلول المتاحة أو تقييد الأضرار المحتملة.`
              : `Legal and regulatory compliance: We may disclose your PII as required by law, including laws outside your country of residence, to comply with legal processes, to respond to requests from public and government authorities, including those outside your country of residence, to enforce our terms and conditions, to protect our operations or those of our affiliates, to safeguard our rights, privacy, safety, or property, and/or that of our affiliates, you, or others, and to pursue available remedies or limit potential damages.`}
          </Paragraph>
          <SubHeading>
            {isRTL ? 'حماية معلوماتك الشخصية' : 'Protection of your personal information'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تستخدم تطبيقنا الجوال صفحات آمنة لجمع معلومات المستخدم، ويتم تخزين البيانات الحساسة في شكل مشفر. نقوم بتنفيذ مجموعة من التدابير التقنية والإدارية لحماية سرية وأمان ونزاهة البيانات المخزنة في نظامنا.`
              : `Our mobile application employs secure pages to gather user information, and highly sensitive data is stored in an encrypted format. We implement a range of technical and administrative measures to safeguard the confidentiality, security, and integrity of data stored within our system.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'الأمان' : 'Security'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `نستخدم إجراءات أمان مناسبة منظمة وتقنية وإدارية لحماية PII تحت سيطرتنا. بينما لا يمكن ضمان أمان نقل البيانات عبر الإنترنت أو نظام تخزين البيانات بشكل مطلق، نحن نسعى جاهدين للحفاظ على أعلى مستويات الحماية. يرجى عدم إرسال المعلومات الحساسة عبر قنوات البريد الإلكتروني غير المأمونة. إذا كنت تشك في أن تفاعلك معنا لم يعد آمنًا (على سبيل المثال، إذا كنت تعتقد أن أمان أي حساب قد تم اختراقه)، يجب عليك إخطارنا فورًا بالمشكلة عن طريق الاتصال بنا وفقًا لقسم "مركز المساعدة" أدناه. يرجى ملاحظة أنه إذا اخترت إخطارنا عبر البريد العادي، سيتسبب ذلك في تأخير وقت الاستجابة لدينا.`
              : `We employ appropriate organizational, technical, and administrative safeguards to protect PII under our control. While no data transmission over the internet or data storage system can be guaranteed to be absolutely secure, we strive to maintain the highest levels of protection. Please refrain from sending sensitive information through unsecured email channels. If you suspect that your interaction with us is no longer secure (for instance, if you believe that the security of any account you might have with us has been compromised), you must immediately notify us of the issue by contacting us in accordance with the "Help Center" section below. Please note that if you choose to notify us via physical mail, this will delay our response time.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'الجهات الثالثة' : 'Third parties'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تشمل سياسة الخصوصية لدينا فقط ممارساتنا فيما يتعلق بجمع المعلومات الشخصية واستخدامها والكشف عنها. لسنا مسؤولين عن ممارسات الخصوصية أو السياسات الخاصة بأي طرف ثالث، بما في ذلك شركائنا أو أي مواقع ويب مرتبطة بتطبيقنا الجوال. وجود رابط على تطبيقنا الجوال لا يشكل تأييدًا للموقع المرتبط من قبلنا أو شركائنا.`
              : `Our Privacy Policy encompasses only our practices regarding the collection, use, and disclosure of personal information. We are not responsible for the privacy practices or policies of any third parties, including our affiliates or any websites linked to our mobile application. The presence of a link on our mobile application does not constitute an endorsement of the linked website by us or our affiliates.`}
          </Paragraph>
          <SubHeading>
            {isRTL ? 'معلومات حول الخدمات التي نقدمها' : 'Information about Services we offer'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `رضاك هو أولويتنا. لتعزيز تجربتك، قد نرسل لك بشكل دوري معلومات حول منتجاتنا، خدماتنا، خصوماتنا، عروضنا الترويجية، وحملاتنا التي نعتقد أنها قد تكون مثيرة لاهتمامك. سنرسل لك الاتصالات التسويقية فقط بموافقتك. إذا لم تعد ترغب في تلقي هذه الاتصالات، يمكنك إلغاء الاشتراك في أي وقت عن طريق الاتصال بنا عبر البريد الإلكتروني أو تحديث تفضيلاتك في إعدادات حسابك.`
              : `Our satisfaction is our priority. To enhance your experience, we may periodically send you information about products, services, discounts, promotions, and campaigns that we believe might be of interest to you. We will only send you marketing communications with your consent. If you no longer wish to receive these communications, you can unsubscribe at any time by contacting us via email or updating your preferences in your account settings.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'تعديل سياسة الخصوصية' : 'Privacy Policy amendment'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `تحتفظ Gomint بالحق في تعديل سياسة الخصوصية في أي وقت. سنقوم بإخطار المستخدمين بأي تغييرات كبيرة من خلال نشر النسخة المحدثة على تطبيقنا الجوال.`
              : `Gomint reserves the right to modify this Privacy Policy at any given time. We will notify users of any significant changes by publishing the updated version on our mobile application.`}
          </Paragraph>
          <SubHeading>
            {isRTL
              ? 'التحقق وتحديث وتعديل المعلومات الشخصية على Gomint.com'
              : 'Verifying, updating, and amending personal information on Gomint.com'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `يمكنك إدارة وتحديث معلوماتك الشخصية في أي وقت عن طريق تسجيل الدخول إلى حسابك والانتقال إلى قسم "الحساب" في صفحة الملف الشخصي.`
              : `You can manage and update your personal information at any time by logging into your account and navigating to the "Account" section under the profile page.`}
          </Paragraph>
          <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} /> {/* Thin gray line */}
          {/* INTRO */}
          <SubHeading
            id="driver-privacy-policy"
            style={{ fontSize: '24px', paddingTop: '20px', paddingBottom: '20px' }}
          >
            {isRTL ? 'سياسة خصوصية السائق' : 'Driver Privacy Policy'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `توضح سياسة الخصوصية هذه كيفية جمعنا واستخدامنا وحمايتنا لمعلوماتك الشخصية عند استخدام منصة Gomint وأداء التسليمات. من خلال استخدام منصتنا، فإنك توافق على الممارسات الموجزة في هذه السياسة.`
              : `This Privacy Policy outlines how we collect, use, and protect your personal information when you use Gomint’s platform and perform deliveries. By using our platform, you consent to the practices described in this policy.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'المعلومات التي نجمعها' : 'Information We Collect'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `نقوم بجمع أنواع مختلفة من المعلومات، بما في ذلك:`
              : `We collect various types of information, including:`}
            <br />
            {isRTL
              ? `المعلومات الشخصية التي قدمتها (مثل الاسم وتفاصيل الاتصال ورخصة القيادة ومعلومات التأمين)`
              : `1- Personal information provided by you (e.g., name, contact details, driver's license, insurance information)`}
            <br />
            {isRTL
              ? `معلومات تتعلق بالتسليم (مثل عناوين التسليم وتفاصيل الطلب)`
              : `2- Delivery-related information (e.g., delivery addresses, order details)`}
            <br />
            {isRTL
              ? `3- معلومات الموقع
              لتمكين خدمات التوصيل، يتطلب تطبيق Gomint Driver الوصول إلى موقع جهازك في الخلفية. نقوم بجمع هذه المعلومات فقط لتمكين تتبع السائق وتحسين عمليات التوصيل. لا يتم مشاركة معلومات موقعك مع أي طرف ثالث لأغراض التسويق.`
              : `3- Location information: We collect this information solely for the purpose of enabling driver tracking and optimizing our delivery operations. Your location information is not shared with any third parties for marketing purposes. We don't save location history in our database just the current location for order tracking, Keep in mind that we only track your location only when your status is (Online) and we keep a notification informing you that you are online.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'الاذونات' : 'Required Permissions'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `ليعمل التطبيق بالشكل الصحيح نحن ستحتاج إلى الاذونات التالية:`
              : `In order for the app to work properly, we need the following permissions:`}
            <br />
            {isRTL
              ? `معلومات الموقع في الخلفيه
              لتمكين خدمات التوصيل، يتطلب تطبيق Gomint Driver الوصول إلى موقع جهازك في الخلفية. نقوم بجمع هذه المعلومات فقط لتمكين تتبع السائق وتحسين عمليات التوصيل. لا يتم مشاركة معلومات موقعك مع أي طرف ثالث لأغراض التسويق.`
              : `Background Location: In order to provide our delivery services, the Gomint Driver app requires access to your device's background location. We collect this information solely for the purpose of enabling driver tracking and optimizing our delivery operations. Your location information is not shared with any third parties for marketing purposes. We don't save location history in our database just the current location for order tracking, Keep in mind that we only track your location only when your status is (Online) and we keep a notification informing you that you are online.`}
            <br />
          </Paragraph>
          <SubHeading>{isRTL ? 'استخدام المعلومات' : 'Use of Information'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL ? `نستخدم المعلومات المجمعة لـ:` : `We use the collected information to:`}
            <br />
            {isRTL
              ? `تسهيل وإدارة التسليمات من خلال منصتنا`
              : `Facilitate and manage deliveries through our platform`}
            <br />
            {isRTL
              ? `التواصل معك بشأن مسائل تتعلق بالتسليم`
              : `Communicate with you regarding delivery-related matters`}
            <br />
            {isRTL
              ? `تحسين وظائف منصتنا وتجربة المستخدم`
              : `Improve our platform's functionality and user experience`}
            <br />
            {isRTL
              ? `إجراء تحليل البيانات والأبحاث لتعزيز خدماتنا`
              : `Conduct data analysis and research to enhance our services`}
            <br />
            {isRTL ? `الامتثال للالتزامات القانونية` : `Comply with legal obligations`}
          </Paragraph>
          <SubHeading>{isRTL ? 'مشاركة المعلومات' : 'Information Sharing'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL ? `قد نشارك معلوماتك مع:` : `We may share your information with:`}
            <br />
            {isRTL
              ? `العملاء ومستخدمي منصتنا لأغراض تنفيذ التسليمات`
              : `Customers and users of our platform for the purpose of fulfilling deliveries`}
            <br />
            {isRTL
              ? `مقدمي خدمات طرف ثالث يساعدون في عمليات التسليم`
              : `Third-party service providers who assist in delivery operations`}
            <br />
            {isRTL
              ? `السلطات القانونية والتنظيمية عند الاقتضاء بموجب القانون أو لحماية حقوقنا`
              : `Legal and regulatory authorities when required by law or to protect our rights`}
            <br />
            {isRTL
              ? `في حالة اندماج تجاري أو استحواذ أو بيع، يمكن نقل معلوماتك إلى الأطراف ذات الصلة`
              : `In the event of a business merger, acquisition, or sale, your information may be transferred to the relevant parties`}
          </Paragraph>
          <SubHeading>{isRTL ? 'أمان البيانات' : 'Data Security'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `نقوم بتنفيذ تدابير أمان لحماية معلوماتك الشخصية من الوصول غير المصرح به والكشف والتغيير والتدمير.`
              : `We implement security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.`}
            <br />
            {isRTL
              ? `ومع ذلك، يرجى ملاحظة أنه لا توجد طريقة نقل أو تخزين بيانات تكون آمنة بشكل تام، ولا يمكننا ضمان الأمان المطلق.`
              : `However, please note that no data transmission or storage method is completely secure, and we cannot guarantee absolute security.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'الاحتفاظ بالمعلومات' : 'Retention of Information'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `نحتفظ بمعلوماتك الشخصية فقط طالما هو ضروري لتحقيق الأغراض الموجزة في سياسة الخصوصية هذه، ما لم تتطلب فترة الاحتفاظ الطويلة أو تسمح بها القانون.`
              : `We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'حقوقك' : 'Your Rights'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `لديك حقوق معينة بشأن معلوماتك الشخصية، بما في ذلك الحق في الوصول وتحديث وتصحيح أو حذف معلوماتك.`
              : `You have certain rights regarding your personal information, including the right to access, update, correct, or delete your information.`}
            <br />
            {isRTL
              ? `قد تكون لديك أيضًا الحق في الاعتراض أو تقييد بعض أنشطة معالجة البيانات. يُرجى الاتصال بنا لممارسة هذه الحقوق.`
              : `You may also have the right to object to or restrict certain data processing activities. Please contact us to exercise these rights.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'روابط الجهات الثالثة' : 'Third-Party Links'}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `قد تحتوي منصتنا على روابط إلى مواقع الويب أو الخدمات من الجهات الثالثة. نحن غير مسؤولين عن ممارسات الخصوصية أو المحتوى في هذه المواقع من الجهات الثالثة.`
              : `Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites.`}
          </Paragraph>
          <SubHeading>{isRTL ? 'خصوصية الأطفال' : "Children's Privacy"}</SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `منصتنا غير مخصصة للاستخدام من قبل الأفراد دون سن الثامنة عشر. نحن لا نجمع بشكل معرف عن عمد معلومات شخصية من الأطفال. إذا كنت تعتقد أننا قمنا بجمع معلومات من طفل بشكل عرضي، يرجى الاتصال بنا.`
              : `Our platform is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us.`}
          </Paragraph>
          <SubHeading>
            {isRTL ? 'تغييرات على سياسة الخصوصية' : 'Changes to the Privacy Policy'}
          </SubHeading>
          <Paragraph isRTL={isRTL}>
            {isRTL
              ? `قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بإخطارك بأي تغييرات مادية عن طريق نشر السياسة المحدثة على موقعنا على الويب أو من خلال وسائل أخرى.`
              : `We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website or through other means.`}
          </Paragraph>
        </MainWrapper>
      </InnerContainer>
    </Container>
  );
};

const popups = keyframes`
  from {
    top: 100%;
  }

  to {
    top: 18%;
  }

`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

export const InnerContainer = styled.div`
  width: 80%;
  height: 90%;
  background-color: #fff;
  border: 1px solid #7e7e7e;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  animation: ${popups} 0.5s ease-in-out backwards;
`;
export const Flex = styled.div<{ reverse: boolean }>`
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px 20px;
  display: flex;
  height: 60px;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  @media (max-width: 1000px) {
    height: 80px;
  }
  @media (max-width: 532px) {
    height: 150px;
  }
`;
export const MainWrapper = styled.div`
  position: relative;
  padding: 0px 3rem;
  align-self: center;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 532px) {
    padding: 0px 2rem;
  }
`;

export const InnerMainWrapper = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Heading = styled.h1`
  font-size: 15px;
  font-weight: 700;
  @media (min-width: 400px) {
    font-size: 24px;
  }
`;
export const SubHeading = styled.h1`
  font-size: 13px;
  font-weight: 700;
  @media (min-width: 400px) {
    font-size: 20px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  margin-top: 5px;
  img {
    width: 20px;
    height: 20px;
  }
`;

export const Text = styled.h3<{ isRTL: boolean }>`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  color: #7e7e7e;
  text-align: ${(props) => (props.isRTL ? 'end' : 'start')};
`;

export const Paragraph = styled.p<{ isRTL: boolean }>`
  font-size: 10px;
  color: #7e7e7e;
  margin: 0px 0px 10px 0px;
  text-align: ${(props) => (props.isRTL ? 'end' : 'start')};
  @media (min-width: 400px) {
    font-size: 15px;
  }
`;

export default PrivacyPolicy;
