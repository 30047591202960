import React from 'react';
import styled from 'styled-components';
import CloseIconImage from '../images/Icon ionic-ios-close-circle-outline.svg';

interface TermsProps {
  // eslint-disable-next-line no-unused-vars
  hide: (value: string) => void;
}
const EmailConfirmation = ({ hide }: TermsProps) => {
  return (
    <Message>
      <Container>
        <Header>
          <CloseIcon onClick={() => hide('')}>
            <img src={CloseIconImage} alt="close-icon" />
          </CloseIcon>
        </Header>
        <Content>
          <h5>Email Confirmed Successfully!</h5>
        </Content>
      </Container>
    </Message>
  );
};
const Message = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  margin-top: 25px;
  width: 20px;
  height: 20px;
`;
const Content = styled.div`
  display: inline-block;
`;

const Container = styled.div`
  background-color: #f3fdff;
  border-radius: 10px;
  min-width: 350px;
  margin-top: 25px;
  padding: 20px 40px;
  border-left: 3px solid;
  border-left-color: #12caf3;
`;

export default EmailConfirmation;
